<template>
  <div id="content" class="app-content">
    <!-- BEGIN container -->
    <b-container>
      <!-- BEGIN row -->
      <b-row class="justify-content-center">
        <!-- BEGIN col-10 -->
        <b-col xl="10">
          <!-- BEGIN row -->
          <b-row>
            <!-- BEGIN col-9 -->
            <b-col xl="9">
              <b-breadcrumb>
                <b-breadcrumb-item :to="{ name: 'toplists' }">TOPLISTS</b-breadcrumb-item>
                <b-breadcrumb-item active>{{ breadcrumbTitle }}</b-breadcrumb-item>
              </b-breadcrumb>

              <!-- page header -->
              <h1 class="page-header">{{ pageTitle }}</h1>
              <hr />

              <b-card>
                <div class="nav-wizards-container">
                  <b-nav class="nav-wizards-2 mb-2">
                    <b-nav-item class="col" :link-classes="stepClass(1)">
                      <div class="nav-text">1. Details</div>
                    </b-nav-item>
                    <b-nav-item class="col" :link-classes="stepClass(2)">
                      <div class="nav-text">2. {{ finalTabTitle }}</div>
                    </b-nav-item>
                  </b-nav>
                </div>

                <hr />

                <keep-alive>
                  <validation-observer v-slot="{ invalid }" v-if="currentStep === 1">
                    <validation-provider name="Title" v-slot="context" rules="required">
                      <b-form-group label="Title">
                        <b-form-input
                          id="formTitleField"
                          placeholder="e.g Top 10"
                          v-model="title"
                          :state="getValidationState(context)"
                          aria-describedby="formTitleFieldError"
                          v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback id="formTitleFieldError" v-bind="context.ariaMsg">{{
                          context.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="Description" v-slot="context">
                      <b-form-group label="Description">
                        <b-form-textarea
                          id="formDescriptionField"
                          placeholder="e.g List of Top 10 products in Stockholm"
                          v-model="description"
                          :state="getValidationState(context)"
                          aria-describedby="formDescriptionFieldError"
                          v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback id="formDescriptionFieldError" v-bind="context.ariaMsg">{{
                          context.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="Link Text" v-slot="context" rules="required">
                      <b-form-group label="Link Text">
                        <b-form-input
                          id="formLinkTextField"
                          placeholder="e.g All Products"
                          v-model="link_text"
                          :state="getValidationState(context)"
                          aria-describedby="formLinkTextFieldError"
                          v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback id="formLinkTextFieldError" v-bind="context.ariaMsg">{{
                          context.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="Link URL" v-slot="context" rules="required">
                      <b-form-group label="Link URL">
                        <b-form-input
                          id="formLinkURLField"
                          placeholder="e.g https://example.com"
                          v-model="link_url"
                          :state="getValidationState(context)"
                          aria-describedby="formLinkURLFieldError"
                          v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback id="formLinkURLFieldError" v-bind="context.ariaMsg">{{
                          context.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <hr />
                    <div class="mt-4">
                      <b-row align-h="end">
                        <b-col cols="auto">
                          <div>
                            <b-button
                              variant="outline-primary"
                              :disabled="invalid"
                              @click="() => setQueryType('query', 2)"
                              class="mr-2"
                              >Setup Query</b-button
                            >

                            <b-button variant="primary" :disabled="invalid" @click="() => setQueryType('products', 2)"
                              >Choose Products</b-button
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </validation-observer>
                </keep-alive>

                <keep-alive>
                  <validation-observer v-slot="{ invalid }" v-if="currentStep === 2">
                    <template v-if="queryType === 'products'">
                      <validation-provider name="Find Products" v-slot="context" rules="required">
                        <b-form-group label="Products">
                          <multi-select
                            v-model="query.products"
                            :options="products"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Choose products"
                            label="text"
                            track-by="value"
                          >
                            <template slot="selection" slot-scope="{ values, search, isOpen }"
                              ><span class="multiselect__single" v-if="values.length && !isOpen"
                                >{{ values.length }} options selected</span
                              ></template
                            >
                          </multi-select>
                          <b-form-invalid-feedback v-bind="context.ariaMsg">{{
                            context.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </template>

                    <template v-if="queryType === 'query'">
                      <validation-provider name="Categories" v-slot="context">
                        <b-form-group label="Categories">
                          <multi-select
                            v-model="query.categories"
                            :options="categories"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Choose categories"
                            label="text"
                            track-by="value"
                          >
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                              <span class="multiselect__single" v-if="values.length && !isOpen">
                                {{ values.length }} options selected
                              </span>
                            </template>
                          </multi-select>

                          <b-form-invalid-feedback v-bind="context.ariaMsg">
                            {{context.errors[0]}}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <b-form-group label="Choose order by formula">
                        <multi-select
                          v-model="orderBy"
                          :options="orderByOptions"
                          :preserve-search="true"
                          :allow-empty="false"
                          placeholder="Choose the order by formula"
                          label="text"
                          track-by="value"
                        >
                        </multi-select>
                      </b-form-group>
                      <b-form-group label="Choose order">
                        <multi-select
                          v-model="orderByDirection"
                          :options="[
                            { text: 'Descending (Highest)', value: 'desc' },
                            { text: 'Ascending (Lowest)', value: 'asc' },
                          ]"
                          :preserve-search="true"
                          :allow-empty="false"
                          placeholder="Choose the order by direction"
                          label="text"
                          track-by="value"
                        >
                        </multi-select>
                      </b-form-group>

                      <validation-provider name="Limit" v-slot="context" rules="required">
                        <b-form-group label="Limit">
                          <b-form-input
                            id="formLimitField"
                            type="number"
                            v-model="limit"
                            :state="getValidationState(context)"
                            aria-describedby="formLimitFieldError"
                            v-bind="context.ariaInput"
                          />

                          <b-form-invalid-feedback id="formLimitFieldError" v-bind="context.ariaMsg">{{
                            context.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </template>

                    <hr />
                    <div class="mt-4">
                      <b-row align-h="between">
                        <b-col cols="auto">
                          <b-button variant="outline-primary" @click="() => setQueryType('', 1)" class="mr-2"
                            >Back</b-button
                          >
                        </b-col>
                        <b-col cols="auto">
                          <b-button variant="primary" :disabled="invalid || isLoading" @click="submitFormData">
                            <b-spinner v-show="isLoading" class="mr-2" small label="Loading" />
                            Submit
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </validation-observer>
                </keep-alive>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-alert
      :show="!!successMessage"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      >{{ successMessage }}</b-alert
    >

    <b-alert
      :show="!!errorMessage"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="danger"
      dismissible
      >{{ errorMessage }}</b-alert
    >
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { getValidationState } from './page-helpers'
import MultiSelect from 'vue-multiselect'
import {products} from "@/api";

export default {
  name: 'ToplistsFormPage',
  components: {
    MultiSelect,
  },
  data() {
    return {
      isLoading: false,
      currentStep: 1,
      queryType: '',
      categories: [],
      orderByOptions: [
        {
          value: 'heat',
          text: 'Heat',
        },
        {
          value: 'purchased_count',
          text: 'Purchase count',
        },
        {
          value: 'created_at',
          text: 'Created at',
        },
        {
          value: 'rating',
          text: 'Rating',
        },
        {
          value: 'last_purcased_at',
          text: 'Latest purchased',
        },
      ],
      orderBy: {
        value: 'heat',
        text: 'Heat',
      },
      orderByDirection: { text: 'Descending (Highest)', value: 'desc' },
      limit: 12,

      // data
      id: 0,
      title: '',
      description: '',
      link_text: '',
      link_url: '',
      query: {
        products: [],
        categories: [],
      },
      products: [],

      // alerts
      successMessage: '',
      errorMessage: '',
    }
  },
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  computed: {
    isEditPage() {
      return this.$route.params.id
    },
    pageTitle() {
      return this.isEditPage ? 'Edit Toplist' : 'New Toplist'
    },
    breadcrumbTitle() {
      return this.isEditPage ? 'EDIT' : 'NEW'
    },
    buttonText() {
      return `${this.isEditPage ? 'Save' : 'Create'} Toplist`
    },
    finalTabTitle() {
      if (this.queryType) {
        return this.queryType === 'products' ? 'Choose Products' : 'Setup Query'
      }
      return 'Manual or Query'
    },
  },
  methods: {
    getValidationState,
    async loadContent() {
      await Promise.all([this.getProducts(), this.getCategories()]).then(([products, categories]) => {
        this.products = products
        this.categories = categories
      })

      if (!this.isEditPage) return

      const toplist = await this.$store.dispatch('toplists/find', this.$route.params.id)

      this.id = toplist.id
      this.title = toplist.title
      this.description = toplist.description
      this.link_text = toplist.link_text
      this.link_url = toplist.link_url
      const includedCategories = toplist.query.categories
      const includedProducts = toplist.query.products
      this.query.products = toplist.products
        .filter(p => includedProducts.includes(p.id))
        .map(p => ({ value: p.id, text: p.name }))

      this.query.categories = this.categories
        .filter(c => includedCategories.includes(c.value))
        .map(c => ({ value: c.value, text: c.text }))

      this.queryType = toplist.query?.products ? 'products' : 'query'
    },
    async getCategories() {
      return (await this.$store.dispatch('categories/all'))
        .data()
        .map(category => ({ value: category.id, text: category.name }))
    },
    async getProducts() {
      return (await products.allForSelect()).map(product => ({ value: product.id, text: product.name }))
    },
    stepClass(step) {
      if (this.currentStep > step) {
        return 'completed'
      }

      return step === this.currentStep ? 'active' : 'disabled'
    },
    gotoStep(step) {
      this.currentStep = step
    },
    setQueryType(type, id) {
      this.queryType = type
      this.gotoStep(id)
    },
    submitFormData() {
      this.isLoading = true
      const data = {
        title: this.title,
        description: this.description,
        link_text: this.link_text,
        link_url: this.link_url,
        query: this.reorderQuery(),
        products: this.products,
      }

      if (this.isEditPage) {
        this.handleToplistUpdate({ ...data, id: this.id })
      } else {
        this.handleToplistCreation(data)
      }

      this.isLoading = false
    },
    reorderQuery() {
      let order = ''
      if (this.orderByDirection.value === 'desc') {
        order += '-'
      }

      order += this.orderBy.value

      return {
        categories: this.query.categories.map(c => c.value),
        products: this.query.products.map(p => p.value),
        limit: this.limit,
        order,
      }
    },
    async handleToplistCreation(payload) {
      const toplist = await this.$store.dispatch('toplists/create', payload)
          .catch(((error) => this.$bvToast.toast(error.message, {title: 'Something went wrong.', variant: 'danger'})))
      this.$router.push({ name: 'toplists.edit', params: { id: toplist.data.id } })
      this.$bvToast.toast(`Toplist with the "${this.title}" created.`, {
        title: 'Toplist created',
        variant: 'primary',
      })
    },
    async handleToplistUpdate(payload) {
      await this.$store.dispatch('toplists/update', payload)
          .catch(((error) => this.$bvToast.toast(error.message, {title: 'Something went wrong.', variant: 'danger'})))
      this.$bvToast.toast(`Toplist with the "${this.title}" updated.`, {
        title: 'Toplist updated',
        variant: 'primary',
      })
    },
  },
}
</script>

<style></style>
